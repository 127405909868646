import React, { useEffect } from 'react';
import { useState } from 'react';
import { graphql } from 'gatsby';
import { Drawer, IndicatorForm, Modal } from '../../components';
import { useData, useDrawer, useModal } from '../../contexts';
import { Indicator, Page } from '../../models';
import { Dashboard } from '../../components/Dashboard/Dashboard';
import { MobileDashboard } from '../../components/MobileDashboard/MobileDashboard';
import { useQuery } from 'react-query';
import { getData } from '../../api';
import { mapToPageModel } from '../../mappers/page.mapper';
import { mapToIndicatorModel } from '../../mappers/indicator.mapper';
import useWindowDimensions from '../../useWindowDimensions/useWindowDimensions';
import { Helmet } from 'react-helmet';
import favicon from '../../images/favicon.ico';

export default function IndicatorDetails({ data: indicatorData }) {
  const indicator: Indicator = mapToIndicatorModel(indicatorData.nodeIndicator);
  const pageData: Page = mapToPageModel(indicatorData.nodePage);

  const {
    selectedIndicator,
    setSelectedIndicator,
    setSelectedIndicatorLong,
    selectedRegio,
    highlightedDimension,
    setDimensions,
    setHighlightedDimension,
    setSelectedYear,
    isBigScreen,
    isDesktop,
    isTablet,
    isMobile,
    setAllDimensions,
    setCategoryField,
    data,
    setData,
    setCategories,
    setSelectedCategory,
    dropdownCategories,
    dropdownCategoriesDefault,
    setHighlightedCategories,
    setXlsLink,
    selectedCategories,
    selectedCategory,
    categories,
  } = useData();

  const { height } = useWindowDimensions();

  const { isOpen, selectedDimension } = useModal();
  const [updateIndicator, setUpdateIndicator] = useState(true);

  // const [isLoading, setIsLoading] = useState(false);
  const { isDrawerOpen } = useDrawer();

  const {
    data: fetchedData,
    isLoading,
    error,
  } = useQuery(`${selectedIndicator}_${selectedRegio}`, () =>
    selectedIndicator !== ''
      ? getData(`${selectedIndicator}_${selectedRegio}.json`)
      : [],
  );

  // const fetchedData = require('../../data/test.json');
  // const isLoading = false;

  const { data: fetchedIndicatorData, isLoading: isLoadingIndicatorData } =
    useQuery('indicatoren', () => getData('Indicatoren.json'));

  const updateStates = async () => {
    // const key = `${selectedIndicator}_${selectedRegio}`;
    // const fetchedData = await require(`../../data/${key}.json`)
    if (selectedIndicator !== '') {
      let dims = {};
      if (fetchedIndicatorData && fetchedIndicatorData.length > 0) {
        if (updateIndicator) {
          const filteredIndicatorData = fetchedIndicatorData
            .filter((d) => {
              return d.Indicator_kort === selectedIndicator;
            })
            .sort((a, b) => parseInt(a.Sortering) - parseInt(b.Sortering));

          dims = filteredIndicatorData.reduce((acc, currentValue) => {
            if (!acc[currentValue.Soort]) {
              acc[currentValue.Soort] = [currentValue.Dimensie];
            } else {
              acc[currentValue.Soort].push(currentValue.Dimensie);
            }
            return acc;
          }, {});

          if (
            !dims[filteredIndicatorData[0].Soort].includes(highlightedDimension)
          )
            setHighlightedDimension(dims[filteredIndicatorData[0].Soort][0]);

          if (filteredIndicatorData && filteredIndicatorData.length !== 0) {
            setCategoryField(filteredIndicatorData[0].Soort);
            setAllDimensions(dims);
            setDimensions(dims[filteredIndicatorData[0].Soort]);
            setCategories(dropdownCategories[filteredIndicatorData[0].Soort]);
            setSelectedCategory(
              dropdownCategoriesDefault[filteredIndicatorData[0].Soort],
            );
            setHighlightedCategories([
              ...dropdownCategories[filteredIndicatorData[0].Soort][
                dropdownCategoriesDefault[filteredIndicatorData[0].Soort]
              ],
            ]);
          } else {
            setCategoryField('');
          }
        }
      }

      if (fetchedData && fetchedData.length > 0) {
        setData(fetchedData);
      } else {
        setData([]);
      }

      if (updateIndicator) setSelectedYear(String('meest recente jaar'));
    } else {
      setData([]);
      setAllDimensions({});
      setDimensions([]);
    }
  };

  const findSource = (dimension: string) => {
    return indicator.sources.find((source) => source.title === dimension);
  };

  useEffect(() => {
    //TODO:
    if (fetchedIndicatorData) {
      const foundIndicator = fetchedIndicatorData.find(
        // (element) => element.Indicator === indicatorData.nodeIndicator.title,
        (element) =>
          element.Indicator_kort ===
          indicatorData.nodeIndicator.field_titel_kort,
      );
      if (foundIndicator) {
        setSelectedIndicator(foundIndicator.Indicator_kort);
        setSelectedIndicatorLong(foundIndicator.Indicator);
      } else {
        setSelectedIndicator('');
        setSelectedIndicatorLong('');
      }
    }
  }, [isLoadingIndicatorData]);

  useEffect(() => {
    if (!isLoading) {
      updateStates();
    }
  }, [fetchedData]);

  useEffect(() => {
    setUpdateIndicator(false);
  }, [selectedRegio]);

  useEffect(() => {
    setUpdateIndicator(true);
  }, [selectedIndicator]);

  useEffect(() => {
    setXlsLink(indicator.file.localFile.publicURL);
  }, []);

  useEffect(() => {
    if (isMobile || isTablet) {
      setHighlightedCategories(categories[selectedCategory]);
    }
  }, [isMobile, isTablet]);

  return (
    <section className="vl-region" style={{ paddingBottom: '0' }}>
      <Helmet>
        <title>De cijfers | Barometer samenleven</title>
        <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
      </Helmet>
      <div style={{ margin: '0 3rem' }}>
        <div className="vl-u-spacer-bottom" style={{ paddingBottom: 20 }}>
          <h1 className="vl-title vl-title--h2">{pageData.title}</h1>
          <p
            className={`vl-typography ${
              (isDesktop || isBigScreen) && 'vl-col--8-12'
            }`}
            dangerouslySetInnerHTML={{ __html: pageData.body }}
          />
        </div>
        <div
          style={{
            minHeight: isBigScreen ? 936 : isDesktop ? 752 : height,
          }}
        >
          {(isBigScreen || isDesktop) && (
            <div className="vl-grid">
              {isBigScreen && (
                <div
                  // className="vl-col--3-12"
                  style={{
                    background: '#E8EBEE',
                    width: '300px',
                    marginLeft: '3rem',
                    paddingRight: '3rem',
                  }}
                >
                  <IndicatorForm currentIndicator={indicator} />
                </div>
              )}

              <div
                style={{
                  width: isBigScreen ? 'calc(100% - 300px - 3rem)' : '100%',
                }}
                className={isBigScreen ? '' : 'vl-u-float-left'}
              >
                <Dashboard isLoading={isLoading} sources={indicator.sources} />
              </div>
            </div>
          )}

          {(isMobile || isTablet) && (
            <MobileDashboard
              sources={indicator.sources}
              isLoading={isLoading}
            ></MobileDashboard>
          )}
        </div>
      </div>
      {isOpen && (
        <Modal
          title={selectedDimension}
          bronHtml={
            findSource(selectedDimension)?.field_bron_content?.processed
          }
          definitieHtml={
            findSource(selectedDimension)?.field_definitie_content?.processed
          }
          bodyHtml={findSource(selectedDimension)?.body?.processed}
        />
      )}

      {!isBigScreen && (
        <Drawer
          isOpen={isDrawerOpen}
          currentIndicator={indicator}
          data={data}
        />
      )}
    </section>
  );
}

export const query = graphql`
  query findOneIndicator($id: String) {
    nodeIndicator(id: { eq: $id }) {
      id
      title
      field_titel_kort
      relationships {
        field_bron {
          title
          field_definitie_content {
            processed
          }
          field_bron_content {
            processed
          }
          body {
            processed
          }
        }
        field_xls {
          localFile {
            publicURL
            name
            extension
            size
          }
        }
      }
    }
    nodePage(id: { eq: "d6029368-4bec-5995-aab3-38a71436c831" }) {
      body {
        processed
      }
      title
    }
  }
`;

import React from 'react';
import ContentLoader from 'react-content-loader';
import { useData } from '../../contexts/useData';
import { Source } from '../../models';
import { DataForm } from '../DataForm/DataForm';
import { DataGrid } from '../DataGrid/DataGrid';
import { MultiLineChart } from '../MultiLineChart/MultiLineChart';

interface Props {
  isLoading: boolean;
  sources: Source[];
}

export const Dashboard = ({ isLoading, sources }: Props) => {
  const { data, isBigScreen, selectedRegio, dimensions } = useData();

  return (
    <div className="vl-col--1-1">
      <DataForm />
      {isLoading ? (
        <ContentLoader viewBox="0 0 380 700">
          {/* Only SVG shapes */}
          <rect x="0" y="4" rx="0" ry="0" width="380" height="10" />
          <rect x="0" y="24" rx="0" ry="0" width="380" height="10" />
          <rect x="0" y="44" rx="0" ry="0" width="380" height="10" />
        </ContentLoader>
      ) : data &&
        data.length > 0 &&
        data.filter((d) => dimensions.includes(d.Dimensie)).length > 0 ? (
        <div>
          <div className="vl-grid">
            <div className="vl-col--1-1"></div>
            <div className="vl-col--1-1">
              <div className="vl-col--1-1">
                <div
                  className="vl-info-tile"
                  style={{
                    border: '1px solid rgba(100,100,100,0)',
                    minHeight: isBigScreen ? 420 : 300,
                  }}
                >
                  <DataGrid />
                </div>
              </div>
            </div>

            <div className="vl-col--1-1">
              <div
                className="vl-info-tile"
                style={{
                  border: '1px solid rgba(100,100,100,0)',
                  paddingBottom: 0,
                }}
              >
                <MultiLineChart sources={sources} />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ minHeight: isBigScreen ? 812 : 620 }}>
          <p className="vl-u-spacer-top">
            {selectedRegio === 'Vlaanderen'
              ? 'Geen data beschikbaar voor het Vlaams Gewest.'
              : 'Geen data beschikbaar voor het Brussels Hoofdstedelijk Gewest.'}
          </p>
        </div>
      )}
    </div>
  );
};

import React, { useEffect, useState } from 'react';
import { useData } from '../../contexts';

import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts';
import useWindowDimensions from '../../useWindowDimensions/useWindowDimensions';
// import { customTooltip } from './MobileMultiLineChart.module.scss';

interface Props {
  dimension: any;
}

export const MobileMultiLineChart = ({ dimension }: Props) => {
  const {
    dimensions,
    categories,
    selectedCategory,
    colors,
    highlightedCategories,
    isMobile,
    selectedYear,
    percentFormatter,
    selectedRegio,
    setHoveredCategory,
    selectedIndicator,
    getColorForCategory,
    data,
    hoveredCategory,
  } = useData();

  const [multiLineData, setMultiLineData] = useState([]);
  const [lineColors, setLineColors] = useState({});
  const [yZoomed, setYZoomed] = useState(true);
  const [yDomain, setYDomain] = useState([0, 'dataMax + 0.03']);
  const [minimumYear, setMinimumYear] = useState(2021);
  const { width, height } = useWindowDimensions();

  const prepMultiLineChartData = async (data, dimension) => {
    let maxYearDim: any = selectedYear;
    if (data && data.length !== 0) {
      let color = colors[0];
      let colorCounter = 0;
      let tmp = {};

      if (selectedYear === 'meest recente jaar') {
        const tmpData = data.filter((el) => {
          return (
            dimension === el.Dimensie &&
            [...categories[selectedCategory]].includes(el['Soort_waarde'])
          );
        });

        const uniqueYears = [...new Set(tmpData.map((d) => d['Jaar']))];

        maxYearDim =
          uniqueYears &&
          uniqueYears.length !== 0 &&
          uniqueYears.reduce((prev, current) =>
            prev > current ? prev : current,
          );
      }

      const years = [
        ...new Set(data.map((element: any) => parseInt(element.Jaar))),
      ];

      let minYear: any = years.reduce((prev, current) =>
        prev < current ? prev : current,
      );

      minYear =
        minYear >= parseInt(maxYearDim) - 9
          ? minYear
          : parseInt(maxYearDim) - 9;

      setMinimumYear(minYear);

      const filteredData = data.filter((d) => {
        return (
          dimension === d.Dimensie &&
          [...categories[selectedCategory]].includes(d['Soort_waarde']) &&
          parseInt(d.Jaar) >= minYear &&
          parseInt(d.Jaar) <= parseInt(maxYearDim)
        );
      });

      const uniqueCat = [
        ...new Set(filteredData.map((d) => d['Soort_waarde'])),
      ];
      if (uniqueCat.includes('Nederlands')) {
        uniqueCat.sort(function (a, b) {
          const keyA = a;
          const keyB = b;
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
          return 0;
        });
      }
      uniqueCat.forEach((d, i) => {
        color = getColorForCategory(colors, highlightedCategories, d, i);
        tmp[d] = color;
      });

      setLineColors(tmp);

      // TODO: mulitLineData vervormen naar onderstaand model

      let mapped = filteredData.map((el) => ({
        year: el.Jaar,
        [el['Soort_waarde']]: el.Verhouding,
      }));

      if (filteredData && filteredData.length !== 0) {
        const yearsFiltered = [
          ...new Set(
            filteredData.map((element: any) => parseInt(element.Jaar)),
          ),
        ];

        const maxYear: any =
          yearsFiltered &&
          yearsFiltered.length !== 0 &&
          yearsFiltered.reduce((prev, current) =>
            prev > current ? prev : current,
          );
        for (let i = maxYear + 1; i <= maxYearDim; i++) {
          mapped.push({ year: parseInt(i), categoryField: null });
        }
      } else {
        mapped.push({ year: parseInt(maxYearDim), categoryField: null });
      }

      var result = [];
      mapped.reduce((res, value) => {
        if (!res[value.year]) {
          res[value.year] = {
            year: value.year,
            [Object.keys(value)[1]]: Object.values(value)[1],
          };
          result.push(res[value.year]);
        }
        res[value.year][Object.keys(value)[1]] = Object.values(value)[1];
        return res;
      }, {});

      result.sort(function (a, b) {
        var keyA = parseInt(a.year),
          keyB = parseInt(b.year);
        // Compare the 2 dates
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      });

      setMultiLineData(result);
    }
  };
  const CustomTooltip = ({ active, payload, label }) => {
    // if (active && payload && payload.length) {
    //   if (
    //     payload[payloadIndex] &&
    //     highlightedCategories.includes(payload[payloadIndex].dataKey) &&
    //     hoveredCategory != ''
    //   ) {
    //     return (
    //       <div
    //         className={`vl-u-text--small ${customTooltip}`}
    //         style={{
    //           padding: 8,
    //           border: '1px solid gray',
    //         }}
    //       >
    //         <p
    //           style={{
    //             fontWeight: 'bold',
    //           }}
    //         >
    //           {payload[0].payload.year}
    //         </p>
    //         <table>
    //           <tbody>
    //             {/* {payload.map((d, i) => {
    //               if (highlightedCategories.includes(d.name)) {
    //                 return (
    //                   <tr key={i}>
    //                     <td>
    //                       <p
    //                         style={{
    //                           textDecoration:
    //                             hoveredCategory === d.name ? 'underline' : '',
    //                           color: fill(d.name),

    //                           paddingLeft: '6px',
    //                           paddingRight: '6px',
    //                         }}
    //                       >
    //                         {d.name}:
    //                       </p>
    //                     </td>
    //                     <td>
    //                       <p
    //                         style={{
    //                           textDecoration:
    //                             // hoverCategory === d.name ? 'underline' : '',
    //                             hoveredCategory === d.name ? 'underline' : '',
    //                           color: fill(d.name),
    //                         }}
    //                       >
    //                         {percentFormatter(d.value)}
    //                       </p>
    //                     </td>
    //                   </tr>
    //                 );
    //               }
    //             })} */}
    //             <tr key={payloadIndex}>
    //               <td>
    //                 <p
    //                   style={{
    //                     color: fill(payload[payloadIndex].name),

    //                     paddingLeft: '6px',
    //                     paddingRight: '6px',
    //                   }}
    //                 >
    //                   {payload[payloadIndex].name}:
    //                 </p>
    //               </td>
    //               <td>
    //                 <p
    //                   style={{
    //                     color: fill(payload[payloadIndex].name),
    //                   }}
    //                 >
    //                     {parseFloat(payload[payloadIndex].value) > 1
    // ? parseInt(payload[payloadIndex].value)
    // : percentFormatter(payload[payloadIndex].value)}
    //                 </p>
    //               </td>
    //             </tr>
    //           </tbody>
    //         </table>
    //       </div>
    //     );
    //   }
    // }
    return '';
  };

  const fill = (element) => {
    return lineColors && lineColors[element] ? lineColors[element] : colors[0];
  };

  const opacity = (element) => {
    let opac = 1;
    if (highlightedCategories.includes(element)) {
      if (hoveredCategory === '') {
        opac = 1;
      } else {
        if (hoveredCategory === element) {
          opac = 1;
        } else {
          if (highlightedCategories.includes(hoveredCategory)) {
            opac = 0.4;
          } else {
            opac = 1;
          }
        }
      }
    } else {
      opac = 0.05;
    }
    return opac;
  };

  const strokeWidthLine = (element) => {
    let strokeWidth = 3;
    if (highlightedCategories.includes(element)) {
      if (hoveredCategory === '') {
        strokeWidth = 3;
      } else {
        if (hoveredCategory === element) {
          strokeWidth = 5;
        } else {
          strokeWidth = 3;
        }
      }
    } else {
      strokeWidth = 3;
    }
    return strokeWidth;
  };

  const dotSize = (element) => {
    let dotSize = 0;
    if (highlightedCategories.includes(element)) {
      if (hoveredCategory === '') {
        dotSize = 0;
      } else {
        if (hoveredCategory === element) {
          dotSize = 6;
        } else {
          dotSize = 0;
        }
      }
    } else {
      dotSize = 0;
    }
    return dotSize;
  };

  const handleMouseOverDot = (e, d) => {
    setHoveredCategory(d.dataKey);
  };
  const handleMouseOutDot = (e, d) => {
    setHoveredCategory('');
  };

  const toggleZoom = () => {
    if (yZoomed) {
      setYDomain(['auto', 'auto']);
    } else {
      setYDomain([0, 'auto']);
    }
  };

  useEffect(() => {
    toggleZoom();
  }, [yZoomed]);

  const handleChangeZoom = (e) => {
    setYZoomed(!yZoomed);
  };

  useEffect(() => {
    prepMultiLineChartData(data, dimension);
  }, [
    highlightedCategories,
    selectedYear,
    selectedCategory,
    selectedRegio,
    categories,
    dimensions,
    selectedIndicator,
    data,
  ]);

  return (
    <div>
      <div className={`vl-col--1-1}`}>
        <div className="vl-u-float-left vl-typography">
          <h5>
            Evolutie
            {/* {minimumYear} - {selectedYear} */}
          </h5>
        </div>
        <div
          className="vl-checkbox--switch__wrapper vl-u-float-right"
          style={{ paddingLeft: 10, paddingRight: 0 }}
        >
          <input
            className="vl-checkbox--switch"
            type="checkbox"
            id={`checkbox-multiLine_${dimension}`}
            name={`checkbox-multiLine_${dimension}`}
            value="1"
            role="switch"
            checked={yZoomed}
            onChange={handleChangeZoom}
            onClick={handleChangeZoom}
          />
          <label
            className="vl-checkbox__label"
            htmlFor={`checkbox-multiLine_${dimension}`}
          >
            <span className="vl-checkbox--switch__label">
              <span aria-hidden="true"></span>
            </span>
            <span>Zoom in</span>
          </label>
        </div>
      </div>
      <div
        className={`vl-col--1-1}`}
        // style={{ paddingTop: '2em', height: height < 760 ? 240 : 300 }}
        style={{
          paddingTop: '2em',
          height: height < 760 ? 240 : 300,
          marginLeft: isMobile && width < 430 ? '-2rem' : 0,
        }}
      >
        <ResponsiveContainer
          width={isMobile && width < 430 ? '110%' : '100%'}
          height="100%"
        >
          <LineChart
            syncId={`lineChartId_${dimension}`}
            data={multiLineData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="year" tick={{ fontSize: '0.7em' }} />
            <YAxis
              tick={{ fontSize: '0.7em' }}
              width={32}
              domain={yDomain}
              tickCount={3}
              tickFormatter={(value) => {
                return dimension === 'Deelname NT2-cursus'
                  ? parseInt(value)
                  : percentFormatter(value);
              }}
            />
            <Tooltip content={<CustomTooltip />} />

            {[...categories[selectedCategory]].map((category, i) => (
              <Line
                animationDuration={500}
                isAnimationActive={multiLineData.length === 1 ? false : true}
                key={i}
                type="linear"
                dataKey={category}
                stroke={fill(category)}
                strokeOpacity={opacity(category)}
                // activeDot={{
                //   r: 8,
                //   onMouseOver: (e, d) => {
                //     setPayloadIndex(i);
                //     handleMouseOverDot(e, d);
                //   },
                //   onMouseOut: handleMouseOutDot,
                //   fill: fill(category),
                //   opacity: opacity(category),
                // }}
                activeDot={{
                  r: 0,
                }}
                dot={{
                  r: multiLineData.length === 1 ? 8 : dotSize(category),
                  stroke: 'white',
                  strokeWidth: 2,
                  fill: fill(category),
                  opacity: opacity(category),
                }}
                strokeWidth={strokeWidthLine(category)}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

import React from 'react';
import { DataForm } from '../DataForm/DataForm';
import { useData, useModal } from '../../contexts';
import { MobileMultiLineChart } from '../MobileMultiLineChart/MobileMultiLineChart';
import { MobileDataGrid } from '../MobileDataGrid/MobileDataGrid';
import ContentLoader from 'react-content-loader';
import { Button } from '..';
import { Source } from '../../models';
import useWindowDimensions from '../../useWindowDimensions/useWindowDimensions';
interface Props {
  isLoading: boolean;
  sources: Source[];
}

export const MobileDashboard = ({ isLoading, sources }: Props) => {
  const { dimensions, data, selectedRegio } = useData();
  const { toggle, setSelectedDimension } = useModal();
  const { height } = useWindowDimensions();

  const handleClickSource = (dimension: string) => {
    setSelectedDimension(dimension);
    toggle();
  };

  const findSource = (dimension: string) => {
    return sources.find((source) => source.title === dimension);
  };

  return (
    <div
      className="vl-col--1-1"
      style={{ minHeight: height > 950 ? 950 - 60 : height - 60 }}
    >
      <DataForm />

      {isLoading ? (
        <ContentLoader viewBox="0 0 380 700">
          {/* Only SVG shapes */}
          <rect x="0" y="4" rx="0" ry="0" width="380" height="10" />
          <rect x="0" y="24" rx="0" ry="0" width="380" height="10" />
          <rect x="0" y="44" rx="0" ry="0" width="380" height="10" />
        </ContentLoader>
      ) : data && data.length > 0 ? (
        dimensions.map((element, i) => {
          return data.filter((d) => element === d.Dimensie).length > 0 ? (
            <div
              key={i}
              style={{
                paddingTop: '2rem',
                paddingBottom: '2rem',
                borderBottom: '1px solid gray',
                // marginTop: i === 0 ? '0' : '2rem',
                marginTop: '2rem',
                minHeight: height > 950 ? 950 - 60 : height - 60,
              }}
            >
              <div className="vl-grid" style={{ paddingBottom: '12px' }}>
                <div className="vl-col--10-12">
                  {/* <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                > */}
                  <p className="vl-introduction">{element}</p>
                  <p
                    className="vl-info-tile__header__subtitle"
                    dangerouslySetInnerHTML={{
                      __html:
                        findSource(element)?.field_definitie_content?.processed,
                    }}
                  />
                  {/* </div> */}
                </div>
                <div
                  className="vl-col--2-12"
                  style={{
                    display: 'flex',
                    justifyContent: 'end',
                    alignItems: 'start',
                    paddingLeft: '0px',
                  }}
                >
                  <Button
                    label="Bron"
                    variant="secondary"
                    onClick={() => handleClickSource(element)}
                  />
                </div>
              </div>

              <div
                className="vl-info-tile"
                style={{
                  border: '1px solid rgba(100,100,100,0)',
                  marginBottom: '8px',
                  minHeight: height < 700 ? 200 : 270,
                }}
              >
                <MobileDataGrid dimension={element} />
              </div>
              <div
                className="vl-info-tile"
                style={{
                  border: '1px solid rgba(100,100,100,0)',
                  marginTop: height >= 820 ? '5rem' : 0,
                }}
              >
                <MobileMultiLineChart dimension={element} />
              </div>
            </div>
          ) : (
            <p className="vl-u-spacer-top">
              {selectedRegio === 'Vlaanderen'
                ? 'Geen data beschikbaar voor het Vlaams Gewest.'
                : 'Geen data beschikbaar voor het Brussels Hoofdstedelijk Gewest.'}
            </p>
          );
        })
      ) : (
        <p className="vl-u-spacer-top">
          {selectedRegio === 'Vlaanderen'
            ? 'Geen data beschikbaar voor het Vlaams Gewest.'
            : 'Geen data beschikbaar voor het Brussels Hoofdstedelijk Gewest.'}
        </p>
      )}
    </div>
  );
};

import React, { useState, useEffect, useRef } from 'react';
import { useData } from '../../contexts';
import { paddingLeft2, padding2, dot } from './MobileDataGrid.module.scss';

import { SimpleBarChart } from '../SimpleBarChart/SimpleBarChart';
import { SimpleLineChart } from '../SimpleLineChart/SimpleLineChart';

// import useLongPress from '../../useLongPress/useLongPress';
import useWindowDimensions from '../../useWindowDimensions/useWindowDimensions';
interface Props {
  dimension: any;
}

export const MobileDataGrid = ({ dimension }: Props) => {
  const {
    dimensions,
    categories,
    selectedCategory,
    colors,
    highlightedCategories,
    selectedYear,
    percentFormatter,
    selectedRegio,
    selectedCategories,
    Tablet,
    Mobile,
    setHoveredCategory,
    categoryField,
    selectedIndicator,
    getColorForCategory,
    setPayloadIndex,
    data,
    hoveredCategory,
    isMobile,
  } = useData();

  const [gridData, setGridData] = useState([]);
  const [checkboxesState, setCheckboxesState] = useState({});
  const { height } = useWindowDimensions();

  const heightRow = height < 780 ? 30 : 36;
  const paddingLabel = (heightRow - 18) / 2;
  const barHeight = 16;

  const scrollToRef = useRef();

  const prepGridData = async (data, dimension) => {
    let maxYearDim: any = selectedYear; // this variable will hold the latest year for which there is data on dimension level
    let maxYearDimCat: any = selectedYear; // this variable will hold the latest year for which there is data on dimension / category level
    let minYearDim: any = selectedYear;

    if (data && data.length !== 0) {
      let color = colors[0];

      const filteredData = data.filter((d) => {
        return (
          dimension === d.Dimensie &&
          categories[selectedCategory].includes(d['Soort_waarde'])
        );
      });

      const uniqueCat = [
        ...new Set(filteredData.map((d) => d['Soort_waarde'])),
      ];
      if (uniqueCat.includes('Nederlands')) {
        uniqueCat.sort(function (a, b) {
          const keyA = a;
          const keyB = b;
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
          return 0;
        });
      }
      const newGridData = uniqueCat.map((d, i) => {
        color = getColorForCategory(colors, highlightedCategories, d, i);

        const tmp = {
          id: i,
          [categoryField]: d,
        };

        if (selectedYear === 'meest recente jaar') {
          const tmpDataCat = data.filter((el) => {
            return el.Dimensie === dimension && el['Soort_waarde'] === d;
          });
          const uniqueYearsCat = [...new Set(tmpDataCat.map((d) => d['Jaar']))];
          maxYearDimCat =
            uniqueYearsCat &&
            uniqueYearsCat.length !== 0 &&
            uniqueYearsCat.reduce((prev, current) =>
              prev > current ? prev : current,
            );

          const tmpData = data.filter((el) => {
            return el.Dimensie === dimension;
          });

          const uniqueYears = [...new Set(tmpData.map((d) => d['Jaar']))];

          maxYearDim =
            uniqueYears &&
            uniqueYears.length !== 0 &&
            uniqueYears.reduce((prev, current) =>
              prev > current ? prev : current,
            );
        }

        const tmpData = data.filter((el) => {
          return (
            el.Dimensie === dimension &&
            parseInt(el.Jaar) > parseInt(maxYearDim) - 10
          );
        });

        const uniqueYears = [...new Set(tmpData.map((d) => d['Jaar']))];

        minYearDim =
          uniqueYears &&
          uniqueYears.length !== 0 &&
          uniqueYears.reduce((prev, current) =>
            prev < current ? prev : current,
          );

        const barData = data.filter((el) => {
          return (
            // el.Jaar === maxYearDim &&
            el.Jaar === maxYearDimCat &&
            el.Dimensie === dimension &&
            el['Soort_waarde'] === d
          );
        });
        barData.color = color;
        barData.valueY = parseFloat(barData.Verhouding);
        barData.unit = dimension === 'Deelname NT2-cursus' ? '' : '%';

        const maxLineValueData = data.filter((el) => {
          return (
            parseInt(el.Jaar) > parseInt(maxYearDim) - 10 &&
            parseInt(el.Jaar) <= parseInt(maxYearDim) &&
            el.Dimensie === dimension &&
            categories[selectedCategory].includes(el['Soort_waarde'])
          );
        });

        const uniqueValues = [
          ...new Set(maxLineValueData.map((d) => d['Verhouding'])),
        ];

        const maxLineValue: any =
          uniqueValues &&
          uniqueValues.length !== 0 &&
          uniqueValues.reduce((prev, current) =>
            prev > current ? prev : current,
          );

        const lineData = data.filter((el) => {
          return (
            parseInt(el.Jaar) > parseInt(maxYearDim) - 10 &&
            parseInt(el.Jaar) <= parseInt(maxYearDim) &&
            el.Dimensie === dimension &&
            el['Soort_waarde'] === d
          );
        });

        if (lineData && lineData.length !== 0) {
          const yearsFiltered = [
            ...new Set(lineData.map((element: any) => parseInt(element.Jaar))),
          ];

          const maxYear: any =
            yearsFiltered &&
            yearsFiltered.length !== 0 &&
            yearsFiltered.reduce((prev, current) =>
              prev > current ? prev : current,
            );

          const minYear: any =
            yearsFiltered &&
            yearsFiltered.length !== 0 &&
            yearsFiltered.reduce((prev, current) =>
              prev < current ? prev : current,
            );

          for (let i = maxYear + 1; i <= maxYearDim; i++) {
            lineData.push({ Dimensie: dimension, Jaar: i, Verhouding: null });
          }
          for (let i = minYearDim; i < minYear; i++) {
            lineData.push({ Dimensie: dimension, Jaar: i, Verhouding: null });
          }
        } else {
          lineData.push({
            Dimensie: dimension,
            Jaar: maxYearDim,
            Verhouding: null,
          });
        }

        lineData.sort(function (a, b) {
          var keyA = parseInt(a.Jaar),
            keyB = parseInt(b.Jaar);
          // Compare the 2 dates
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
          return 0;
        });
        lineData.color = color;
        lineData.max = parseInt(maxLineValue);
        lineData.unit = dimension === 'Deelname NT2-cursus' ? '' : '%';

        let delta = null;
        if (data && data.length !== 0) {
          const previousYear = data.filter((el) => {
            return (
              parseInt(el.Jaar) === parseInt(maxYearDimCat) - 1 &&
              el.Dimensie === dimension &&
              el['Soort_waarde'] === d
            );
          });
          const currentYear = data.filter((el) => {
            return (
              parseInt(el.Jaar) === parseInt(maxYearDimCat) &&
              el.Dimensie === dimension &&
              el['Soort_waarde'] === d
            );
          });

          if (
            currentYear &&
            currentYear.length > 0 &&
            previousYear &&
            previousYear.length > 0
          ) {
            delta = currentYear[0].Verhouding - previousYear[0].Verhouding;
          }
        }

        const markData = delta;
        tmp[dimension] = { bar: barData, line: lineData, mark: markData };
        return tmp;
      });
      setGridData(newGridData);
    }
  };

  // const handleClickCategory = (e) => {
  //   let tmp = e.currentTarget.id.substring(3);
  //   let tmpArray = [...highlightedCategories];
  //   if (highlightedCategories.includes(tmp)) {
  //     tmpArray = arrayRemoveValue(tmpArray, tmp);
  //   } else {
  //     tmpArray.push(tmp);
  //   }
  //   setHighlightedCategories(tmpArray);
  // };

  // const handleClickCategoryLongPress = (realTarget) => {
  //   let tmp = realTarget.id.substring(3);
  //   let tmpArray = [...highlightedCategories];
  //   if (highlightedCategories.includes(tmp)) {
  //     tmpArray = arrayRemoveValue(tmpArray, tmp);
  //   } else {
  //     tmpArray.push(tmp);
  //   }
  //   setHighlightedCategories(tmpArray);
  // };

  useEffect(() => {
    updateCheckboxesState();
    prepGridData(data, dimension);
  }, [
    highlightedCategories,
    selectedYear,
    selectedCategory,
    selectedRegio,
    categories,
    dimensions,
    selectedIndicator,
    data,
  ]);

  function arrayRemoveValue(arr, value) {
    return arr.filter(function (ele) {
      return ele != value;
    });
  }

  const updateCheckboxesState = () => {
    let tmp = {};
    selectedCategories.forEach((category) => {
      tmp[category] = highlightedCategories.includes(category) ? true : false;
    });
    setCheckboxesState(tmp);
  };

  const stroke = (element, dim) => {
    return element[dim] ? element[dim].line.color : colors[0];
  };

  const fill = (element, dim) => {
    return element[dim] ? element[dim].bar.color : colors[0];
  };

  const opacity = (element) => {
    let opac = 1;
    if (highlightedCategories.includes(element[categoryField])) {
      opac = 1;
    } else {
      opac = 0.05;
    }
    return opac;
  };

  const opacityBar = (element, dim) => {
    let opac = 1;
    if (highlightedCategories.includes(element[categoryField])) {
      if (hoveredCategory === '') {
        opac = 1;
      } else {
        if (hoveredCategory === element[categoryField]) {
          opac = 1;
        } else {
          if (highlightedCategories.includes(hoveredCategory)) {
            opac = 0.4;
          } else {
            opac = 1;
          }
        }
      }
    } else {
      opac = 0.05;
    }
    return opac;
  };

  const opacityCategory = (element) => {
    let opac = 1;
    if (highlightedCategories.includes(element[categoryField])) {
      if (hoveredCategory === '') {
        opac = 1;
      } else {
        if (hoveredCategory === element[categoryField]) {
          opac = 1;
        } else {
          if (highlightedCategories.includes(hoveredCategory)) {
            opac = 0.4;
          } else {
            opac = 1;
          }
        }
      }
    } else {
      opac = 0.2;
    }
    return opac;
  };

  const opacityText = (element) => {
    let opac = 1;
    if (highlightedCategories.includes(element[categoryField])) {
      opac = 1;
    } else {
      opac = 0.3;
    }
    return opac;
  };

  const mouseOverHandler = (e) => {
    let i = [...categories[selectedCategory]].indexOf(hoveredCategory);
    setPayloadIndex(i);
  };

  const handleClickCategory = (e) => {
    let tmp = e.currentTarget.id.substring(3);
    hoveredCategory === tmp ? setHoveredCategory('') : setHoveredCategory(tmp);
  };

  // const onLongPress = (realTarget) => {
  //   let tmp = realTarget.id.substring(3);
  //   hoveredCategory === tmp ? setHoveredCategory('') : setHoveredCategory(tmp);
  // };

  // const onClick = (realTarget) => {
  //   handleClickCategoryLongPress(realTarget);
  // };

  // const defaultOptions = {
  //   shouldPreventDefault: true,
  //   delay: 500,
  // };

  // const longPressEvent = useLongPress(onLongPress, onClick, defaultOptions);

  if (dimension !== undefined) {
    return (
      <div>
        {/* <button onClick={() => scrollToRef.current.scrollIntoView()}>
          Scroll
        </button> */}
        <div className={`vl-col--1-1}`} ref={scrollToRef}>
          <table className="vl-data-table vl-data-table--grid">
            <caption
              className="vl-info-tile__header__title"
              style={{ width: '100%', textAlign: 'left' }}
            >
              Situatie {selectedYear}
            </caption>
            <tbody>
              {gridData.map((element, i) => {
                if (element[dimension] !== undefined) {
                  return (
                    <tr
                      key={element.id}
                      id={`tr_${element[categoryField]}`}
                      onClick={handleClickCategory}
                      // {...longPressEvent}
                      style={{ cursor: 'pointer' }}
                    >
                      <td
                        id={`tr_${element[categoryField]}`}
                        data-title="Categorisatie"
                        style={{
                          minWidth: isMobile ? 140 : 160,
                          width: isMobile ? 140 : 160,
                          padding: `${paddingLabel}px 0.2rem`,
                          verticalAlign: 'middle',
                        }}
                        // onMouseEnter={(e) => {
                        //   handleMouseEnterCategory(e);
                        // }}
                        // onMouseLeave={handleMouseLeaveCategory}
                      >
                        <p
                          style={{
                            textDecorationLine: highlightedCategories.includes(
                              element[categoryField],
                            )
                              ? 'underline'
                              : 'none',
                            textDecorationColor: fill(element, dimension),
                            opacity: opacityText(element),
                          }}
                        >
                          <span
                            className={`${dot}`}
                            style={{
                              backgroundColor: fill(element, dimension),
                              opacity: opacityCategory(element),
                            }}
                          ></span>
                          {element[categoryField]}
                        </p>
                      </td>

                      <td
                        id={`tr_${element[categoryField]}`}
                        data-title={dimension}
                        key={dimension}
                        // className={`vl-col--1-4 ${padding2}`}
                        className={`${padding2}`}
                        // onMouseEnter={(e) => {
                        //   handleMouseEnterCategory(e);
                        // }}
                        // onMouseLeave={handleMouseLeaveCategory}
                      >
                        <Tablet>
                          <div
                            id={`tr_${element[categoryField]}`}
                            className={`vl-col--8-12 vl-u-float-left ${paddingLeft2} `}
                            style={{ height: heightRow }}
                          >
                            <SimpleBarChart
                              element={element}
                              dim={dimension}
                              fill={fill(element, dimension)}
                              opacity={opacityBar(element, dimension)}
                              dataKey="Verhouding"
                              barSize={barHeight}
                            />
                          </div>
                          <div
                            id={`tr_${element[categoryField]}`}
                            className={`vl-col--2-12 vl-u-float-left ${paddingLeft2}`}
                            style={{ height: heightRow }}
                            onMouseOver={mouseOverHandler}
                          >
                            <SimpleLineChart
                              element={element}
                              dim={dimension}
                              dataKey="Verhouding"
                              stroke={stroke(element, dimension)}
                              opacity={opacityBar(element, dimension)}
                            />
                          </div>
                          <div
                            id={`tr_${element[categoryField]}`}
                            className={`vl-col--2-12 vl-u-float-left ${paddingLeft2} `}
                            style={{ height: heightRow }}
                          >
                            <div
                              id={`tr_${element[categoryField]}`}
                              style={{
                                height: heightRow,
                                textAlign: 'end',
                                paddingTop: paddingLabel,
                                paddingRight: 6,
                              }}
                            >
                              {element[dimension]
                                ? element[dimension].mark === null
                                  ? '/'
                                  : dimension === 'Deelname NT2-cursus'
                                  ? element[dimension].mark > 0
                                    ? `+${parseInt(element[dimension].mark)}`
                                    : parseInt(element[dimension].mark)
                                  : element[dimension].mark > 0
                                  ? `+${percentFormatter(
                                      element[dimension].mark,
                                    )}`
                                  : percentFormatter(element[dimension].mark)
                                : null}
                            </div>
                          </div>
                        </Tablet>
                        <Mobile>
                          <div
                            id={`tr_${element[categoryField]}`}
                            className={`vl-col--9-12 vl-u-float-left ${paddingLeft2} `}
                            style={{ height: heightRow }}
                          >
                            <SimpleBarChart
                              element={element}
                              dim={dimension}
                              fill={fill(element, dimension)}
                              opacity={opacityBar(element, dimension)}
                              dataKey="Verhouding"
                              barSize={14}
                            />
                          </div>
                          <div
                            id={`tr_${element[categoryField]}`}
                            className={`vl-col--3-12 vl-u-float-left ${paddingLeft2} `}
                            style={{ height: heightRow }}
                          >
                            <div
                              id={`tr_${element[categoryField]}`}
                              style={{
                                height: heightRow,
                                textAlign: 'end',
                                paddingTop: paddingLabel,
                                paddingRight: 6,
                              }}
                            >
                              {element[dimension]
                                ? element[dimension].mark === null
                                  ? '/'
                                  : dimension === 'Deelname NT2-cursus'
                                  ? element[dimension].mark > 0
                                    ? `+${parseInt(element[dimension].mark)}`
                                    : parseInt(element[dimension].mark)
                                  : element[dimension].mark > 0
                                  ? `+${percentFormatter(
                                      element[dimension].mark,
                                    )}`
                                  : percentFormatter(element[dimension].mark)
                                : null}
                            </div>
                          </div>
                        </Mobile>
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
};
